import { z } from 'zod'

export function joinTextSpaces(text: string, replaceValue = '-') {
  return text.replaceAll(' ', replaceValue)
}

export function parseSafeArrayStrings<T = string[]>(
  value: unknown,
  transform = (strings: string[]): T => {
    return strings as T
  }
) {
  // eslint-disable-next-line promise/prefer-await-to-then
  return z.array(z.string()).catch([]).transform(transform).parse(value)
}
